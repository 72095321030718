import { ReactNode, Suspense, createContext, lazy, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Loading from './Components/Loading/Loading';
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from 'react-cookie-consent';
import { WEBSERVER_API_ROOT } from './Components/DataLayer/GlobalSettings';
import { load_script } from './Components/GeneralFunctions/ScriptLoader';

const ComponentDesktop = lazy(() => import("./Components/Desktop/Desktop"));
const ComponentTablet = lazy(() => import("./Components/Tablet/Tablet"));
const ComponentMobile = lazy(() => import("./Components/Mobile/Mobile"));

export const AppContext = createContext({});

function App() {

  //cookies related text and boolean
  const [ cookiesAccepted, setCookiesAccepted ] = useState(getCookieConsentValue() === "true" ? true : false);
  const [ txtThisWebsiteUsesCookies, setTxtThisWebsiteUsesCookies ] = useState("...");
  const [ txtIAccept, setTxtIAccept ] = useState("...");
  const [ txtIDecline, setTxtIDecline ] = useState("...");

  const [ windowState, setWindowState ] = useState(window.innerWidth >= 1199 ? "desktop " : window.innerWidth > 767 ? "tablet" : "mobile");
  const [ viewToShow, setViewToShow ] = useState<ReactNode | null>(null);
  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
  const [ isLoadingInitialData, setIsLoadingInitialData ] = useState(true);
  const [ countries, setCountries ] = useState<any[]>([]);
  const [ countryCode, setCountryCode ] = useState(localStorage.getItem("countryCode") || "not set");
  const [ translations, setTranslations ] = useState<any[]>([]);
  const [ translationsLoaded, setTranslationsLoaded ] = useState(false);


  const appContext = {
    countryCode,
    updateCountryCode,
    countries,
    translations,
    setTranslations,
    translationsLoaded
  }


  //load scripts for google - if cookies are accepted
  useEffect(() => {
    if(cookiesAccepted) {
      //new consent mode 2.0:
      window.gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted'
      });

      ["https://www.googletagmanager.com/gtag/js?id=G-RN4PVV9S2Y"
      ].forEach(load_script);
    }
  }, [ cookiesAccepted ]);

  //setup change of view when screen resolution changes
  //load all countries info from database, also load countryCode from ip after inital page load
  //after countryCode is set, load translations for language
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    setIsLoadingInitialData(true);

    if (countries.length === 0) {
      fetch(WEBSERVER_API_ROOT + "Countries.php")
        .then(response => response.json())
        .then(json => setCountries(json));
    }

    if(countryCode === "not set" && countries.length > 0) {
      setCountryCode("NO");
    }

    //after countryCode is set, load translations for language and set app ready to load
    //after translations are loaded, set txt variables for cookieConsent
    if(countryCode && countryCode !== "not set" && countries.length > 0) {
      //find language code from countryCode in countries
      const languageCode = countries.filter((item) => item.country_code === countryCode)[0].language_code.trim();
      
      fetch(WEBSERVER_API_ROOT + "Translations.php?languageCode=" + languageCode)
        .then(response => response.json())
        .then(json => {
          const localTranslations = json;
          setTranslations(prev => json);
          setIsLoadingInitialData(false);
          setTranslationsLoaded((prev) => true);

          //translate text variables for cookieConsent
          const textThisWebsiteUsesCookies = "This website uses cookies to enhance the user experience. The cookies are from";
          const translatedTextThisWebsiteUsesCookies = json.filter((item: any) => item.original === textThisWebsiteUsesCookies)[0]?.translated;
          if(translatedTextThisWebsiteUsesCookies) {
            setTxtThisWebsiteUsesCookies(translatedTextThisWebsiteUsesCookies);
          } else {
            const languageCode = (countries.filter((item) => item.country_code === countryCode))[0].language_code.trim();
            fetch(WEBSERVER_API_ROOT + "Translate.php?to=" + languageCode + "&textToTranslate=" + encodeURIComponent(textThisWebsiteUsesCookies))
              .then(response => response.json())
              .then(json => {
                setTxtThisWebsiteUsesCookies(json.translated);
                setTranslations([...localTranslations, {original: textThisWebsiteUsesCookies, translated: json.translated}]);
              });
          }
          const textIAccept = "I accept";
          const translatedTextIAccept = json.filter((item: any) => item.original === textIAccept)[0]?.translated;
          if(translatedTextIAccept) {
            setTxtIAccept(translatedTextIAccept);
          } else {
            const languageCode = (countries.filter((item) => item.country_code === countryCode))[0].language_code.trim();
            fetch(WEBSERVER_API_ROOT + "Translate.php?to=" + languageCode + "&textToTranslate=" + encodeURIComponent(textIAccept))
              .then(response => response.json())
              .then(json => {
                setTxtIAccept(json.translated);
                setTranslations([...localTranslations, {original: textIAccept, translated: json.translated}]);
              });
          }
          const textIDecline = "I decline";
          const translatedTextIDecline = json.filter((item: any) => item.original === textIDecline)[0]?.translated;
          if(translatedTextIDecline) {
            setTxtIDecline(translatedTextIDecline);
          } else {
            const languageCode = (countries.filter((item) => item.country_code === countryCode))[0].language_code.trim();
            fetch(WEBSERVER_API_ROOT + "Translate.php?to=" + languageCode + "&textToTranslate=" + encodeURIComponent(textIDecline))
              .then(response => response.json())
              .then(json => {
                setTxtIDecline(json.translated);
                setTranslations([...localTranslations, {original: textIDecline, translated: json.translated}]);
              });
          }    
        });
    }

    return (() => {
      window.removeEventListener("resize", handleWindowSizeChange);
    });

  }, [ countryCode, countries ]);

  const updateWindowState = () => {
    switch(true) {
      case window.innerWidth >= 1200:
        setWindowState("desktop");
        break;
      case window.innerWidth > 767 && 
        window.innerWidth < 1200:
        setWindowState("tablet");
        break;
      case window.innerWidth < 767:
        setWindowState("mobile");
        break;
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateWindowState);

    updateWindowState();

    return () => {window.removeEventListener("resize", updateWindowState)}
  }, []);
  
  useEffect(() => {
    switch(windowState) {
      case "desktop":
        setViewToShow(<ComponentDesktop />);
        break;
      case "tablet":
        setViewToShow(<ComponentTablet />);
        break;
      case "mobile":
        setViewToShow(<ComponentMobile />);
        break;
    }
  }, [ windowState ]);

  //general functions
  function handleWindowSizeChange(): void {
    setWindowWidth(window.innerWidth);
  }

  function updateCountryCode(newCountryCode: string) {
    setTranslations([]);
    setTranslationsLoaded(prev => false);
    setCountryCode(newCountryCode);
    if(cookiesAccepted) localStorage.setItem("countryCode", newCountryCode);
  }

  //cookies functions
  function cookiesAcceptedFunction() {
    localStorage.setItem("countryCode", countryCode);
    setCookiesAccepted(true);
  }

  function cookiesDeclinedFunction() {
    resetCookieConsentValue();
  }

  if(isLoadingInitialData) return <Loading />;

  return (
    <BrowserRouter>
      <AppContext.Provider value={appContext}>
        <div className="App">
          <Suspense fallback={<Loading />}>
            {viewToShow}
          </Suspense>
          <CookieConsent 
            location="bottom" 
            enableDeclineButton
            onAccept={() => cookiesAcceptedFunction()}
            onDecline={() => cookiesDeclinedFunction()}
            buttonText={txtIAccept}
            declineButtonText={txtIDecline}
          >
            <div style={{textAlign: "center"}}>
              {txtThisWebsiteUsesCookies}
              <div>
                <div>
                  <div>Google Analytics</div>
                  <div>KafeKoigen.no</div>
                </div>
              </div>
            </div>
          </CookieConsent>
        </div>
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;
